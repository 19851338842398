import React from "react"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"

import "bootstrap/dist/css/bootstrap.min.css"
import "react-multi-carousel/lib/styles.css"

import "./src/assets/css/bootstrap.css"
import "./src/assets/css/global.css"
import "./src/assets/css/shapes.css"

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname !== prevLocation?.pathname) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }
}

export const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
)
