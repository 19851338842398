exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-csr-index-js": () => import("./../../../src/pages/csr/index.js" /* webpackChunkName: "component---src-pages-csr-index-js" */),
  "component---src-pages-customs-agents-index-js": () => import("./../../../src/pages/customs-agents/index.js" /* webpackChunkName: "component---src-pages-customs-agents-index-js" */),
  "component---src-pages-customs-offices-country-js": () => import("./../../../src/pages/customs-offices/country.js" /* webpackChunkName: "component---src-pages-customs-offices-country-js" */),
  "component---src-pages-customs-offices-data-js": () => import("./../../../src/pages/customs-offices/data.js" /* webpackChunkName: "component---src-pages-customs-offices-data-js" */),
  "component---src-pages-customs-offices-index-js": () => import("./../../../src/pages/customs-offices/index.js" /* webpackChunkName: "component---src-pages-customs-offices-index-js" */),
  "component---src-pages-data-protection-index-js": () => import("./../../../src/pages/data-protection/index.js" /* webpackChunkName: "component---src-pages-data-protection-index-js" */),
  "component---src-pages-dexter-index-js": () => import("./../../../src/pages/dexter/index.js" /* webpackChunkName: "component---src-pages-dexter-index-js" */),
  "component---src-pages-ebooks-index-js": () => import("./../../../src/pages/ebooks/index.js" /* webpackChunkName: "component---src-pages-ebooks-index-js" */),
  "component---src-pages-forwarding-companies-index-js": () => import("./../../../src/pages/forwarding-companies/index.js" /* webpackChunkName: "component---src-pages-forwarding-companies-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-companies-index-js": () => import("./../../../src/pages/industrial-companies/index.js" /* webpackChunkName: "component---src-pages-industrial-companies-index-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-master-data-index-js": () => import("./../../../src/pages/master-data/index.js" /* webpackChunkName: "component---src-pages-master-data-index-js" */),
  "component---src-pages-mission-index-js": () => import("./../../../src/pages/mission/index.js" /* webpackChunkName: "component---src-pages-mission-index-js" */),
  "component---src-pages-nocode-index-js": () => import("./../../../src/pages/nocode/index.js" /* webpackChunkName: "component---src-pages-nocode-index-js" */),
  "component---src-pages-ports-country-js": () => import("./../../../src/pages/ports/country.js" /* webpackChunkName: "component---src-pages-ports-country-js" */),
  "component---src-pages-ports-data-js": () => import("./../../../src/pages/ports/data.js" /* webpackChunkName: "component---src-pages-ports-data-js" */),
  "component---src-pages-ports-index-js": () => import("./../../../src/pages/ports/index.js" /* webpackChunkName: "component---src-pages-ports-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-procedures-index-js": () => import("./../../../src/pages/procedures/index.js" /* webpackChunkName: "component---src-pages-procedures-index-js" */),
  "component---src-pages-taric-index-js": () => import("./../../../src/pages/taric/index.js" /* webpackChunkName: "component---src-pages-taric-index-js" */),
  "component---src-pages-tariff-numbers-data-js": () => import("./../../../src/pages/tariff-numbers/data.js" /* webpackChunkName: "component---src-pages-tariff-numbers-data-js" */),
  "component---src-pages-tariff-numbers-index-js": () => import("./../../../src/pages/tariff-numbers/index.js" /* webpackChunkName: "component---src-pages-tariff-numbers-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-widget-index-js": () => import("./../../../src/pages/widget/index.js" /* webpackChunkName: "component---src-pages-widget-index-js" */),
  "component---src-templates-blog-category-template-js": () => import("./../../../src/templates/blog-category-template.js" /* webpackChunkName: "component---src-templates-blog-category-template-js" */),
  "component---src-templates-blog-details-template-js": () => import("./../../../src/templates/blog-details-template.js" /* webpackChunkName: "component---src-templates-blog-details-template-js" */),
  "component---src-templates-integration-template-js": () => import("./../../../src/templates/integration-template.js" /* webpackChunkName: "component---src-templates-integration-template-js" */),
  "component---src-templates-testimonial-details-template-js": () => import("./../../../src/templates/testimonial-details-template.js" /* webpackChunkName: "component---src-templates-testimonial-details-template-js" */)
}

