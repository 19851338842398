import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"

// Initialize i18n
i18n
  .use(Backend) // Load translations from your JSON files
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    fallbackLng: "en", // Default fallback language
    supportedLngs: [
      "en",
      "de",
      "es",
      "sq",
      "nl",
      "fr",
      "it",
      "tr",
      "ru",
      "sv",
      "da",
      "fi",
      "no",
    ], // List of supported languages
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path to your translation files
    },
    nsSeparator: false, // Disable namespace separator (not needed for your setup)
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
    react: {
      useSuspense: false, // Handle loading state manually in Gatsby
    },
  })

export default i18n
